import * as Types from '../../../gql/types2';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"graphql2"}} as const;
export type CreateProjectRequestMutationVariables = Types.Exact<{
  input: Types.CreateProjectRequestCommand;
}>;


export type CreateProjectRequestMutation = { __typename?: 'MutationModel', projectRequests: { __typename?: 'ProjectRequestMutationModel', create: { __typename?: 'CreateProjectRequestResponse', requestId?: any | null, errors: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string }> } } };


export const CreateProjectRequestDocument = gql`
    mutation CreateProjectRequest($input: CreateProjectRequestCommand!) {
  projectRequests {
    create(input: $input) {
      requestId
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CreateProjectRequestMutationFn = Apollo.MutationFunction<CreateProjectRequestMutation, CreateProjectRequestMutationVariables>;

/**
 * __useCreateProjectRequestMutation__
 *
 * To run a mutation, you first call `useCreateProjectRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectRequestMutation, { data, loading, error }] = useCreateProjectRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectRequestMutation, CreateProjectRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectRequestMutation, CreateProjectRequestMutationVariables>(CreateProjectRequestDocument, options);
      }
export type CreateProjectRequestMutationHookResult = ReturnType<typeof useCreateProjectRequestMutation>;
export type CreateProjectRequestMutationResult = Apollo.MutationResult<CreateProjectRequestMutation>;
export type CreateProjectRequestMutationOptions = Apollo.BaseMutationOptions<CreateProjectRequestMutation, CreateProjectRequestMutationVariables>;