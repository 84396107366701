import React, { useState } from "react";
import Button from "~/button";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import FormBottomRow from "~/form-bottom-row";
import { useSnackBar } from "~/snackbar";
import { useEditGroupOfficeMutation } from "./queries/editGroupOffice.hotchoc.generated";
import FieldOfficePickerFormField from "~/visuals/organisms/FieldOfficePickerFormField";
import { FieldOffice, ValidationResponse } from "~/gql/types";
import TextFormField from "~/text-form-field";
import { useGroupReviewContext } from "../GroupReview/groupReviewContext";

type GroupEditOfficeProps = {
  office: FieldOffice;
  memberId: string;
};

type FormData = {
  notes: string | null;
  office: FieldOffice | null;
};

export const GroupOfficeForm: React.FC<GroupEditOfficeProps> = ({
  office,
  memberId,
}) => {
  const [open, setOpen] = useState(false);
  const [doMutation] = useEditGroupOfficeMutation();
  const addAlert = useSnackBar();
  const { refetch } = useGroupReviewContext();

  const onSuccess = () => {
    addAlert({
      isSuccess: true,
      message: "Office edited",
      key: `${Math.random()}`,
    });
    setOpen(false);
    void refetch();
  };

  const runMutation = async (values: FormData) => {
    const input = {
      officeCode: values.office!.officeCode,
      memberId,
      notes: values.notes!,
    };
    const res = await doMutation({ variables: { input } });

    return res?.data?.projectRequests?.editGroupOffice as ValidationResponse;
  };

  const initialValues: FormData = {
    office,
    notes: null,
  };

  return (
    <>
      <dt className="request-field-label">Office Code</dt>
      <dd>
        {office.officeCode}
        <Button
          className="group-edit-button open-edit-office-button"
          outlined
          onClick={() => setOpen(true)}
        >
          Edit
        </Button>
      </dd>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        data-open={open}
        className="edit-group-office-dialog"
      >
        <DialogTitle>
          Editing the office code will apply to the entire group
        </DialogTitle>
        <DialogContent>
          {open && (
            <MutationForm
              initialValues={initialValues}
              runMutation={runMutation}
              onSuccess={onSuccess}
            >
              <FieldOfficePickerFormField
                formField={"office"}
                label={"Office"}
                helperText={""}
              />
              <TextFormField
                formField={"notes"}
                label={"Reason"}
                helperText={
                  "Please provide the reason for editing group office"
                }
              />

              <ErrorRenderer
                render={(formError) => (
                  <FormBottomRow
                    errorMessage={formError}
                    buttonText="Submit"
                    onCancel={() => setOpen(false)}
                  />
                )}
              />
            </MutationForm>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
