import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserEmployeeRecordQueryVariables = Types.Exact<{
  userPrincipalName: Types.Scalars['String'];
}>;


export type GetUserEmployeeRecordQuery = { __typename?: 'QueryModel', employees?: { __typename?: 'EmployeesQueryModel', single?: { __typename?: 'Employee', workLocation: string, fieldOffice?: { __typename?: 'FieldOffice', officeCode: string, allowsProjects: boolean } | null } | null } | null };


export const GetUserEmployeeRecordDocument = gql`
    query GetUserEmployeeRecord($userPrincipalName: String!) {
  employees {
    single(userPrincipalName: $userPrincipalName) {
      workLocation
      fieldOffice {
        officeCode
        allowsProjects
      }
    }
  }
}
    `;

/**
 * __useGetUserEmployeeRecordQuery__
 *
 * To run a query within a React component, call `useGetUserEmployeeRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEmployeeRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEmployeeRecordQuery({
 *   variables: {
 *      userPrincipalName: // value for 'userPrincipalName'
 *   },
 * });
 */
export function useGetUserEmployeeRecordQuery(baseOptions: Apollo.QueryHookOptions<GetUserEmployeeRecordQuery, GetUserEmployeeRecordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserEmployeeRecordQuery, GetUserEmployeeRecordQueryVariables>(GetUserEmployeeRecordDocument, options);
      }
export function useGetUserEmployeeRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserEmployeeRecordQuery, GetUserEmployeeRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserEmployeeRecordQuery, GetUserEmployeeRecordQueryVariables>(GetUserEmployeeRecordDocument, options);
        }
export type GetUserEmployeeRecordQueryHookResult = ReturnType<typeof useGetUserEmployeeRecordQuery>;
export type GetUserEmployeeRecordLazyQueryHookResult = ReturnType<typeof useGetUserEmployeeRecordLazyQuery>;
export type GetUserEmployeeRecordQueryResult = Apollo.QueryResult<GetUserEmployeeRecordQuery, GetUserEmployeeRecordQueryVariables>;