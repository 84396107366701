import React from "react";
import Button from "~/button";
import { Headline6 } from "@material/react-typography";
import List, { ListItem, ListItemText } from "~/list";
import {
  assignProjectExpenseRateSheet,
  assignProjectTaskRateSheet,
  viewExpenseRateSheet,
  viewTaskRateSheet,
} from "~/routes";
import { Link } from "react-router-dom";
import { ProjectDetailsProps, DateDisplay } from "./types";
import { useUser } from "~/Auth/useUser";
import ClickSwallower from "~/click-swallower";
import { useConfirmationPrompt } from "~/confirmation-prompt";
import { useRemoveTaskRateSheetMutation } from "./removeTaskSheet.generated";
import { useRemoveExpenseRateSheetMutation } from "./removeExpenseSheet.generated";
import { useSnackBar } from "~/snackbar";
import { useReloadProject } from "~/visuals/pages/Project/ProjectRouter";
import Spinner from "~/spinner";
import { CommandAuthorization } from "~/gql/types2";

type RateSheet = {
  rateSheet: { name: string };
  effectiveDate: string;
  type: "Task" | "Expense";
};

export const RateSheets: React.FC<ProjectDetailsProps> = ({ project }) => {
  const user = useUser();
  const authMap = {
    canAssignTaskSheet: user.commands.has(
      CommandAuthorization.AssignTaskRateSheetToProjectCommand
    ),
    canAssignExpenseSheet: user.commands.has(
      CommandAuthorization.AssignToProjectCommand
    ),
    canRemoveTaskSheet: user.commands.has(
      CommandAuthorization.RemoveTaskRateSheetFromProjectCommand
    ),
    canRemoveExpenseSheet: user.commands.has(
      CommandAuthorization.RemoveExpenseRateSheetFromProjectCommand
    ),
  };

  const prompt = useConfirmationPrompt();
  const [removeTaskSheet, { loading: removeTaskSheetLoading }] =
    useRemoveTaskRateSheetMutation();
  const [removeExpenseSheet, { loading: removeExpenseSheetLoading }] =
    useRemoveExpenseRateSheetMutation();
  const addAlert = useSnackBar();
  const reloadProject = useReloadProject();

  const openDeleteConfirmation = async (sheet: RateSheet) => {
    const promptResult = await prompt({
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes, remove rate sheet",
      message: "Are you sure you want to remove this rate sheet assignment?",
      title: "Remove rate",
    });

    if (promptResult !== "Confirm") {
      return;
    }

    await removeRate(sheet);
  };

  const removeRate = async (sheet: RateSheet) => {
    const mutation =
      sheet.type === "Task" ? removeTaskSheet : removeExpenseSheet;
    const variables = {
      projectNumbers: [project.number],
      rateSheetName: sheet?.rateSheet?.name,
      effectiveDate: sheet?.effectiveDate,
    };

    const type = sheet.type === "Task" ? "taskRates" : "expenseRates";

    const result = await mutation({ variables });
    const data = result?.data?.[type]?.removeRateSheetFromProject;

    if (data?.errors?.length > 0) {
      const errors: string[] = [];
      data.errors.forEach(({ argumentName, message }) => {
        if (argumentName) {
          errors.push(`${argumentName}: ${message}`);
        } else if (message) {
          errors.push(message);
        }
      });

      addAlert({
        key: `${Math.random()}`,
        message: errors.join(" "),
        isSuccess: false,
      });
    } else {
      addAlert({
        key: `${Math.random()}`,
        message: `${sheet.type} rate sheet assignment removed`,
        isSuccess: true,
      });
    }

    reloadProject();
  };

  const SheetSecondaryText: React.FC<{ sheet: RateSheet }> = ({ sheet }) => {
    const canRemove =
      sheet?.type === "Task"
        ? authMap.canRemoveTaskSheet
        : authMap.canRemoveExpenseSheet;
    return (
      <span className="mdc-list-item__secondary-text">
        Effective <DateDisplay date={sheet?.effectiveDate} />
        {canRemove && (
          <ClickSwallower>
            <Button
              icon="delete"
              className={
                sheet?.type === "Task"
                  ? "remove-task-sheet"
                  : "remove-expense-sheet"
              }
              onClick={() => openDeleteConfirmation(sheet)}
            />
          </ClickSwallower>
        )}
      </span>
    );
  };

  return (
    <>
      <div className="rate-sheets-view">
        <div>
          <Headline6>
            Task Rate Sheets
            {authMap.canAssignTaskSheet && (
              <Button
                icon="add"
                dense
                route={assignProjectTaskRateSheet.toRoute(project.number)}
              >
                Assign Task Rate Sheet
              </Button>
            )}
          </Headline6>
          <List>
            {project.taskRateSheets!.map((sheet) => (
              <Link
                key={sheet?.rateSheet?.name}
                to={viewTaskRateSheet.toRoute(sheet?.rateSheet?.name!).path}
              >
                <ListItem>
                  <ListItemText
                    primaryText={sheet?.rateSheet?.name}
                    secondaryText={
                      <SheetSecondaryText
                        sheet={{ ...sheet, type: "Task" } as RateSheet}
                      />
                    }
                  />
                </ListItem>
              </Link>
            ))}
          </List>
        </div>
        <div>
          <Headline6>
            Expense Rate Sheets
            {authMap.canAssignExpenseSheet && (
              <Button
                icon="add"
                dense
                route={assignProjectExpenseRateSheet.toRoute(project.number)}
              >
                Assign Expense Rate Sheet
              </Button>
            )}
          </Headline6>
          <List>
            {project.expenseRateSheets!.map((sheet) => (
              <Link
                key={sheet?.rateSheet?.name}
                to={viewExpenseRateSheet.toRoute(sheet?.rateSheet?.name!).path}
              >
                <ListItem>
                  <ListItemText
                    primaryText={sheet?.rateSheet?.name}
                    secondaryText={
                      <SheetSecondaryText
                        sheet={{ ...sheet, type: "Expense" } as RateSheet}
                      />
                    }
                  />
                </ListItem>
              </Link>
            ))}
          </List>
        </div>
      </div>
      <Spinner open={removeTaskSheetLoading || removeExpenseSheetLoading} />
    </>
  );
};
