import moment from "moment";
import { Project } from "../visuals/pages/Project/ProjectDetails/types";
import { projectFieldMap } from "./defaultProjectFieldState";
import { FieldState } from "~/inline-fields-form/types";

export type ProjectStatus = "Active" | "Closed";

export function createEditFieldState(project: Project): FieldState[] {
  const { projectSupervisor, projectManager, billingAdmin, location, status } =
    project;

  const projectNumber = project.number;
  const errorResult = "errors { argumentName message }";

  let fieldStates = [
    {
      ...projectFieldMap["status"],
      mutation: () => `editStatus(
        status: $status
        projectNumbers: [${projectNumber}]
      ) { ${errorResult} }`,
      required: true,
      value: { name: status },
      variables: ["$status: ProjectStatus!"],
    },
    {
      ...projectFieldMap["customer"],
      mutation: () => `editCustomer(
        customerNumber: $customerNumber
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.customer,
      variables: ["$customerNumber: String!"],
    },
    {
      ...projectFieldMap["dateIn"],
      mutation: () => `editDateIn(
        dateIn: $dateIn
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: moment(project.dateIn, "YYYY-MM-DD"),
      variables: ["$dateIn: LocalDate!"],
    },
    {
      ...projectFieldMap["clientContactName"],
      mutation: () => `editClientContactName(
        clientContactName: $clientContactName
        projectNumbers: [${projectNumber}]
      ) { ${errorResult} }`,
      value: project.clientContactName,
      variables: ["$clientContactName: String!"],
    },
    {
      ...projectFieldMap["officeCode"],
      name: "officeCode",
      mutation: () => `editOfficeCode(
        officeCode: $officeCode
        projectNumbers: [${projectNumber}]
      ) { ${errorResult} }`,
      value: { officeCode: project.officeCode, allowsProjects: true },
      variables: ["$officeCode: String!"],
    },
    {
      ...projectFieldMap["projectManager"],
      mutation: () => `editProjectManager(
        userPrincipalName: $projectManager
        projectNumbers: [${projectNumber}]
      ) { ${errorResult} }`,
      value: projectManager,
      variables: ["$projectManager: String!"],
    },
    {
      ...projectFieldMap["projectSupervisor"],
      mutation: () => `editProjectSupervisor(
        userPrincipalName: $projectSupervisor
        projectNumbers: [${projectNumber}]
      ) { ${errorResult} }`,
      value: projectSupervisor,
      variables: ["$projectSupervisor: String!"],
    },
    {
      ...projectFieldMap["billingAdmin"],
      mutation: () => `editBillingAdmin(
        userPrincipalName: $billingAdmin
        projectNumbers: [${projectNumber}]
      ) { ${errorResult} }`,
      value: billingAdmin,
      variables: ["$billingAdmin: String!"],
    },
    {
      ...projectFieldMap["estimatedStartDate"],
      mutation: () => `editEstimatedStartDate(
        estimatedStartDate: $estimatedStartDate
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.estimatedStartDate
        ? moment(project.estimatedStartDate, "YYYY-MM-DD")
        : null,
      variables: ["$estimatedStartDate: LocalDate"],
    },
    {
      ...projectFieldMap["estimatedEndDate"],
      mutation: () => `editEstimatedEndDate(
        estimatedEndDate: $estimatedEndDate
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.estimatedEndDate
        ? moment(project.estimatedEndDate, "YYYY-MM-DD")
        : null,
      variables: ["$estimatedEndDate: LocalDate"],
    },
    {
      ...projectFieldMap["actualStartDate"],
      mutation: () => `editActualStartDate(
        actualStartDate: $actualStartDate
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.actualStartDate
        ? moment(project.actualStartDate, "YYYY-MM-DD")
        : null,
      variables: ["$actualStartDate: LocalDate"],
    },
    {
      ...projectFieldMap["actualEndDate"],
      mutation: () => `editActualEndDate(
        actualEndDate: $actualEndDate
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.actualEndDate
        ? moment(project.actualEndDate, "YYYY-MM-DD")
        : null,
      variables: ["$actualEndDate: LocalDate"],
    },
    {
      ...projectFieldMap["name"],
      mutation: () => `editProjectName(
        name: $name
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.name,
      variables: ["$name: String!"],
    },
    {
      ...projectFieldMap["description"],
      name: "description",
      mutation: () => `editDescription(
        description: $description
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.description,
      variables: ["$description: String!"],
    },
    {
      ...projectFieldMap["location"],
      mutation: () => `editLocation(
        state: $state
        county: $county
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: location,
      variables: ["$state: String!", "$county: String!"],
    },
    {
      ...projectFieldMap["afe"],
      mutation: () => `editAfe(
        afe: $afe
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.afe,
      variables: ["$afe: String"],
    },
    {
      ...projectFieldMap["section"],
      mutation: () => `editSection(
        section: $section
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.section,
      variables: ["$section: String"],
    },
    {
      ...projectFieldMap["township"],
      mutation: () => `editTownship(
        township: $township
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.township,
      variables: ["$township: String"],
    },
    {
      ...projectFieldMap["range"],
      mutation: () => `editRange(
        range: $range
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.range,
      variables: ["$range: String"],
    },
    {
      ...projectFieldMap["block"],
      mutation: () => `editBlock(
        block: $block
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.block,
      variables: ["$block: String"],
    },
    {
      ...projectFieldMap["survey"],
      mutation: () => `editSurvey(
        survey: $survey
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.survey,
      variables: ["$survey: String"],
    },
    {
      ...projectFieldMap["latitude"],
      mutation: () => `editLatitude(
        latitude: $latitude
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.latitude,
      variables: ["$latitude: Decimal"],
    },
    {
      ...projectFieldMap["longitude"],
      mutation: () => `editLongitude(
        longitude: $longitude
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.longitude,
      variables: ["$longitude: Decimal"],
    },
    {
      ...projectFieldMap["networkPath"],
      mutation: () => `editNetworkPath(
        networkPath: $networkPath
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.networkPath,
      variables: ["$networkPath: String"],
    },
    {
      ...projectFieldMap["internalNotes"],
      mutation: () => `editInternalNotes(
        internalNotes: $internalNotes
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.internalNotes,
      variables: ["$internalNotes: String!"],
    },
    {
      ...projectFieldMap["invoiceNotes"],
      mutation: () => `editInvoiceNotes(
        invoiceNotes: $invoiceNotes
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.invoiceNotes,
      variables: ["$invoiceNotes: String!"],
    },
    {
      ...projectFieldMap["division"],
      mutation: () => `editDivision(
        divisionId: $divisionId
        projectNumbers: [${projectNumber}]
      ) { ${errorResult} }`,
      value: project.division,
      variables: ["$divisionId: String!"],
    },
    {
      ...projectFieldMap["corpLocation"],
      mutation: () => `editCorpLocation(
        corpLocationId: $corpLocationId
        projectNumbers: [${projectNumber}]
      ) { ${errorResult} }`,
      value: project.corpLocation,
      variables: ["$corpLocationId: String!"],
    },
    {
      ...projectFieldMap["serviceDescription"],
      mutation: () => `editServiceDescription(
        serviceDescription: $serviceDescription
        projectNumbers: [${projectNumber}]
      ) { ${errorResult} }`,
      value: project.serviceDescription,
      variables: ["$serviceDescription: String!"],
    },
    {
      ...projectFieldMap["governmentFunding"],
      name: "governmentFunding",
      mutation: () => `editGovernmentFunding(
        governmentFunding: $governmentFunding
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: { name: project.governmentFunding },
      variables: ["$governmentFunding: GovernmentFundingType!"],
    },
    {
      ...projectFieldMap["billingFrequency"],
      mutation: () => `editBillingFrequency(
        billingFrequency: $billingFrequency
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: { name: project.billingFrequency },
      variables: ["$billingFrequency: BillingFrequencyType!"],
    },
    {
      ...projectFieldMap["budget"],
      mutation: () => `editBudget(
        budget: $budget
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.budget ? project.budget.toFixed(2) : 0,
      variables: ["$budget: Decimal"],
    },
    {
      ...projectFieldMap["scope"],
      name: "scope",
      mutation: () => `editScope(
        scope: $scope
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.scope,
      variables: ["$scope: String"],
    },
    {
      ...projectFieldMap["certifiedPayroll"],
      name: "certifiedPayroll",
      mutation: () => `editCertifiedPayroll(
        certifiedPayroll: $certifiedPayroll
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.certifiedPayroll ?? false,
      variables: ["$certifiedPayroll: Boolean!"],
    },
    {
      ...projectFieldMap["projectGroup"],
      name: "projectGroup",
      mutation: () => `editProjectGroup(
        projectGroupName: $projectGroupName
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.projectGroup
        ? { name: project.projectGroup.name, customer: project.customer }
        : null,
      variables: ["$projectGroupName: String"],
    },
    {
      ...projectFieldMap["projectOrigination"],
      mutation: () => `editProjectOrigination(
        projectOrigination: $projectOrigination
        name: $projectOriginationName
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.projectOrigination
        ? {
            name: project.projectOriginationName,
            origination: project.projectOrigination,
          }
        : null,
      variables: [
        "$projectOrigination: ProjectOrigination!",
        "$projectOriginationName: String",
      ],
    },
    {
      ...projectFieldMap["allowThirdPartyInvoice"],
      name: "allowThirdPartyInvoice",
      mutation: () => `editAllowThirdPartyInvoice(
        allowThirdPartyInvoice: $allowThirdPartyInvoice
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.allowThirdPartyInvoice ?? false,
      variables: ["$allowThirdPartyInvoice: Boolean!"],
    },
    {
      ...projectFieldMap.po,
      mutation: () => `editPo(
        po: $po
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.po,
      variables: ["$po: String"],
    },
    {
      ...projectFieldMap["wo"],
      mutation: () => `editWo(
        wo: $wo
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.wo,
      variables: ["$wo: String"],
    },
    {
      ...projectFieldMap["contractNumber"],
      mutation: () => `editContractNumber(
        contractNumber: $contractNumber
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.contractNumber,
      variables: ["$contractNumber: String"],
    },
    {
      ...projectFieldMap["glCode"],
      mutation: () => `editGlCode(
        glCode: $glCode
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.glCode,
      variables: ["$glCode: String"],
    },
    {
      ...projectFieldMap["costCenter"],
      mutation: () => `editCostCenter(
        costCenter: $costCenter
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.costCenter,
      variables: ["$costCenter: String"],
    },
    {
      ...projectFieldMap["requisitioner"],
      mutation: () => `editRequisitioner(
        requisitioner: $requisitioner
        projectNumber: ${projectNumber}
      ) { ${errorResult} }`,
      value: project.requisitioner,
      variables: ["$requisitioner: String"],
    },
  ];

  if (!project.canEdit) {
    fieldStates = [...fieldStates].map((x) => ({ ...x, disabled: true }));
  }

  return fieldStates as FieldState[];
}
