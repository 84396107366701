import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateProjectMutationVariables = Types.Exact<{
  latitude?: Types.InputMaybe<Types.Scalars['Decimal']>;
  longitude?: Types.InputMaybe<Types.Scalars['Decimal']>;
  township?: Types.InputMaybe<Types.Scalars['String']>;
  division: Types.Scalars['String'];
  dateIn?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  name: Types.Scalars['String'];
  section?: Types.InputMaybe<Types.Scalars['String']>;
  internalNotes: Types.Scalars['String'];
  projectSupervisor: Types.Scalars['String'];
  projectManager: Types.Scalars['String'];
  description: Types.Scalars['String'];
  invoiceNotes: Types.Scalars['String'];
  range?: Types.InputMaybe<Types.Scalars['String']>;
  officeCode: Types.Scalars['String'];
  customerNumber: Types.Scalars['String'];
  actualStartDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  state: Types.Scalars['String'];
  afe?: Types.InputMaybe<Types.Scalars['String']>;
  po?: Types.InputMaybe<Types.Scalars['String']>;
  wo?: Types.InputMaybe<Types.Scalars['String']>;
  costCenter?: Types.InputMaybe<Types.Scalars['String']>;
  contractNumber?: Types.InputMaybe<Types.Scalars['String']>;
  glCode?: Types.InputMaybe<Types.Scalars['String']>;
  requisitioner?: Types.InputMaybe<Types.Scalars['String']>;
  estimatedEndDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  billingAdmin: Types.Scalars['String'];
  actualEndDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  corpLocation: Types.Scalars['String'];
  budget?: Types.InputMaybe<Types.Scalars['Decimal']>;
  billingFrequency: Types.BillingFrequencyType;
  survey?: Types.InputMaybe<Types.Scalars['String']>;
  estimatedStartDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  governmentFunding: Types.GovernmentFundingType;
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  block?: Types.InputMaybe<Types.Scalars['String']>;
  clientContactName: Types.Scalars['String'];
  networkPath?: Types.InputMaybe<Types.Scalars['String']>;
  serviceDescription: Types.Scalars['String'];
  county: Types.Scalars['String'];
  scope: Types.Scalars['String'];
  certifiedPayroll: Types.Scalars['Boolean'];
  projectGroupName?: Types.InputMaybe<Types.Scalars['String']>;
  projectOrigination: Types.ProjectOrigination;
  projectOriginationName?: Types.InputMaybe<Types.Scalars['String']>;
  allowThirdPartyInvoice: Types.Scalars['Boolean'];
}>;


export type CreateProjectMutation = { __typename?: 'MutationModel', projects?: { __typename?: 'ProjectsMutationModel', createProject?: { __typename?: 'CreateProjectResponse', projectNumber?: number | null, errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const CreateProjectDocument = gql`
    mutation CreateProject($latitude: Decimal, $longitude: Decimal, $township: String, $division: String!, $dateIn: LocalDate, $name: String!, $section: String, $internalNotes: String!, $projectSupervisor: String!, $projectManager: String!, $description: String!, $invoiceNotes: String!, $range: String, $officeCode: String!, $customerNumber: String!, $actualStartDate: LocalDate, $state: String!, $afe: String, $po: String, $wo: String, $costCenter: String, $contractNumber: String, $glCode: String, $requisitioner: String, $estimatedEndDate: LocalDate, $billingAdmin: String!, $actualEndDate: LocalDate, $corpLocation: String!, $budget: Decimal, $billingFrequency: BillingFrequencyType!, $survey: String, $estimatedStartDate: LocalDate, $governmentFunding: GovernmentFundingType!, $projectId: Int, $block: String, $clientContactName: String!, $networkPath: String, $serviceDescription: String!, $county: String!, $scope: String!, $certifiedPayroll: Boolean!, $projectGroupName: String, $projectOrigination: ProjectOrigination!, $projectOriginationName: String, $allowThirdPartyInvoice: Boolean!) {
  projects {
    createProject(
      latitude: $latitude
      longitude: $longitude
      township: $township
      division: $division
      dateIn: $dateIn
      name: $name
      section: $section
      internalNotes: $internalNotes
      projectSupervisor: $projectSupervisor
      projectManager: $projectManager
      description: $description
      invoiceNotes: $invoiceNotes
      range: $range
      officeCode: $officeCode
      customerNumber: $customerNumber
      actualStartDate: $actualStartDate
      state: $state
      afe: $afe
      po: $po
      wo: $wo
      costCenter: $costCenter
      contractNumber: $contractNumber
      glCode: $glCode
      requisitioner: $requisitioner
      estimatedEndDate: $estimatedEndDate
      billingAdmin: $billingAdmin
      actualEndDate: $actualEndDate
      corpLocation: $corpLocation
      budget: $budget
      billingFrequency: $billingFrequency
      survey: $survey
      estimatedStartDate: $estimatedStartDate
      governmentFunding: $governmentFunding
      projectId: $projectId
      block: $block
      clientContactName: $clientContactName
      networkPath: $networkPath
      serviceDescription: $serviceDescription
      county: $county
      scope: $scope
      certifiedPayroll: $certifiedPayroll
      projectGroupName: $projectGroupName
      projectOrigination: $projectOrigination
      projectOriginationName: $projectOriginationName
      allowThirdPartyInvoice: $allowThirdPartyInvoice
    ) {
      projectNumber
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      township: // value for 'township'
 *      division: // value for 'division'
 *      dateIn: // value for 'dateIn'
 *      name: // value for 'name'
 *      section: // value for 'section'
 *      internalNotes: // value for 'internalNotes'
 *      projectSupervisor: // value for 'projectSupervisor'
 *      projectManager: // value for 'projectManager'
 *      description: // value for 'description'
 *      invoiceNotes: // value for 'invoiceNotes'
 *      range: // value for 'range'
 *      officeCode: // value for 'officeCode'
 *      customerNumber: // value for 'customerNumber'
 *      actualStartDate: // value for 'actualStartDate'
 *      state: // value for 'state'
 *      afe: // value for 'afe'
 *      po: // value for 'po'
 *      wo: // value for 'wo'
 *      costCenter: // value for 'costCenter'
 *      contractNumber: // value for 'contractNumber'
 *      glCode: // value for 'glCode'
 *      requisitioner: // value for 'requisitioner'
 *      estimatedEndDate: // value for 'estimatedEndDate'
 *      billingAdmin: // value for 'billingAdmin'
 *      actualEndDate: // value for 'actualEndDate'
 *      corpLocation: // value for 'corpLocation'
 *      budget: // value for 'budget'
 *      billingFrequency: // value for 'billingFrequency'
 *      survey: // value for 'survey'
 *      estimatedStartDate: // value for 'estimatedStartDate'
 *      governmentFunding: // value for 'governmentFunding'
 *      projectId: // value for 'projectId'
 *      block: // value for 'block'
 *      clientContactName: // value for 'clientContactName'
 *      networkPath: // value for 'networkPath'
 *      serviceDescription: // value for 'serviceDescription'
 *      county: // value for 'county'
 *      scope: // value for 'scope'
 *      certifiedPayroll: // value for 'certifiedPayroll'
 *      projectGroupName: // value for 'projectGroupName'
 *      projectOrigination: // value for 'projectOrigination'
 *      projectOriginationName: // value for 'projectOriginationName'
 *      allowThirdPartyInvoice: // value for 'allowThirdPartyInvoice'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;