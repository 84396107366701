import React from "react";
import { Employee } from "~/gql/types";
import { ImpersonationContext } from "./ImpersonationManager";
import { AuthenticationManagerContext } from "./AuthenticationManager/AuthenticationManagerDisplay";
import sign from "jwt-encode";
import { Action } from "./AuthenticationManager/stateMachine";
import {
  Authorizations,
  GetAuthorizationsAsync,
  LegacyAuthorizations,
} from "./types";
import { ClientAuthorizations, CommandAuthorization } from "~/gql/types2";

export type MockUserProviderProps = {
  getAuthorizations?: GetAuthorizationsAsync;
  authorizations?: Partial<Authorizations>;
  impersonatedEmployee?: Employee;
  impersonatedAuths?: Authorizations;
  token?: string;
  dispatch?: React.Dispatch<Action>;
  setEmployee?: React.Dispatch<Employee | null>;
};

export const defaultMockUserEmail = "person@person.com";

const exampleToken = sign(
  { name: "Person Personson", email: defaultMockUserEmail },
  "secret"
);

const defaultClientAuths: ClientAuthorizations = {
  menus: [],
  commands: [
    CommandAuthorization.AssignTaskRateSheetToProjectCommand,
    CommandAuthorization.AssignToProjectCommand,
    CommandAuthorization.RemoveExpenseRateSheetFromProjectCommand,
    CommandAuthorization.RemoveTaskRateSheetFromProjectCommand,
  ],
};

export const defaultAuths = {
  legacyAuth: {
    impersonate: true,
    manageInvoices: true,
    reviewWorkData: true,
    isMobileEmployee: true,
    usesWorkflowTimeEntry: true,
    reviewWorkWeeks: true,
    approveChargeAdjustments: true,
    editProjectCharges: true,
    manageFieldOffices: true,
    manageRateSheets: true,
    requestCustomer: true,
    viewCustomer: true,
    viewPayrollAdminData: true,
    approveThirdPartyInvoices: true,
    createThirdPartyInvoices: true,
  },
  auth: defaultClientAuths,
} as Authorizations;

export const MockUserProvider: React.FC<MockUserProviderProps> = ({
  getAuthorizations,
  authorizations,
  token,
  impersonatedEmployee,
  impersonatedAuths,
  dispatch,
  setEmployee,
  children,
}) => {
  const allAuthorizations: Authorizations = {
    ...defaultAuths,
    auth: {
      ...defaultAuths.auth,
      ...authorizations?.auth!,
    },
    legacyAuth: {
      ...defaultAuths.legacyAuth,
      ...authorizations?.legacyAuth!,
    },
  };

  return (
    <AuthenticationManagerContext.Provider
      value={{
        jwt: token ?? exampleToken,
        authorizations: allAuthorizations,
        dispatch: dispatch ?? (() => undefined),
      }}
    >
      <ImpersonationContext.Provider
        value={{
          getAuthorizations:
            getAuthorizations ?? (() => new Promise(() => allAuthorizations)),
          employee: impersonatedEmployee ?? null,
          setEmployee: setEmployee ?? (() => undefined),
          validating: false,
          setValidating: () => undefined,
          authorizations: impersonatedAuths ?? null,
          setAuthorizations: () => undefined,
        }}
      >
        {children}
      </ImpersonationContext.Provider>
    </AuthenticationManagerContext.Provider>
  );
};

export function wrapLegacyAuths(
  auths?: Partial<LegacyAuthorizations>
): Authorizations {
  return {
    auth: {} as ClientAuthorizations,
    legacyAuth: (auths ?? defaultAuths.legacyAuth) as LegacyAuthorizations,
  };
}
