import * as Types from '../../gql/types2';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"graphql2"}} as const;
export type ApproveProjectRequestMutationVariables = Types.Exact<{
  input: Types.ApproveRequestGroupCommand;
}>;


export type ApproveProjectRequestMutation = { __typename?: 'MutationModel', projectRequests: { __typename?: 'ProjectRequestMutationModel', approveGroup: { __typename?: 'ValidationResponse', errors: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string }> } } };


export const ApproveProjectRequestDocument = gql`
    mutation ApproveProjectRequest($input: ApproveRequestGroupCommand!) {
  projectRequests {
    approveGroup(input: $input) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type ApproveProjectRequestMutationFn = Apollo.MutationFunction<ApproveProjectRequestMutation, ApproveProjectRequestMutationVariables>;

/**
 * __useApproveProjectRequestMutation__
 *
 * To run a mutation, you first call `useApproveProjectRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveProjectRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveProjectRequestMutation, { data, loading, error }] = useApproveProjectRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveProjectRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApproveProjectRequestMutation, ApproveProjectRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveProjectRequestMutation, ApproveProjectRequestMutationVariables>(ApproveProjectRequestDocument, options);
      }
export type ApproveProjectRequestMutationHookResult = ReturnType<typeof useApproveProjectRequestMutation>;
export type ApproveProjectRequestMutationResult = Apollo.MutationResult<ApproveProjectRequestMutation>;
export type ApproveProjectRequestMutationOptions = Apollo.BaseMutationOptions<ApproveProjectRequestMutation, ApproveProjectRequestMutationVariables>;