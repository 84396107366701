import React from "react";
import TextFormField from "~/text-form-field";
import { useSnackBar } from "~/snackbar";
import {
  projectRequests,
  projectRequest as projectRequestRoute,
} from "~/routes";
import PageTitleRow from "~/page-title-row";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import FormCard from "~/form-card";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { useNavigate } from "react-router-dom";
import FormBottomRow from "~/form-bottom-row";
import Spinner from "~/spinner";
import { ProjectRequest, ValidationResponse } from "~/gql/types";
import {
  RejectProjectRequestMutationVariables,
  useRejectProjectRequestMutation,
} from "./mutation.hotchoc.generated";

type RequestProjectRequestProps = {
  projectRequest: ProjectRequest;
};

type FormValues = {
  reason: string | null;
};

const initialFormValues = {
  reason: null,
};

export const RejectProjectRequest: React.FC<RequestProjectRequestProps> = ({
  projectRequest,
}) => {
  const addAlert = useSnackBar();
  const navigate = useNavigate();
  const [doMutation, { loading }] = useRejectProjectRequestMutation();
  useBreadcrumbs(
    [
      { text: "Project Requests", to: projectRequests.path },
      {
        text: `Pending Request ${projectRequest.name}`,
        to: projectRequestRoute.toRoute(projectRequest.requestId!).path,
      },
      { text: "Reject" },
    ],
    [projectRequest]
  );

  const onSuccess = () => {
    addAlert({
      isSuccess: true,
      message: `Project request ${projectRequest.name} rejected`,
      key: `${Math.random()}`,
    });
    navigate(projectRequests.path);
  };

  const runMutation = async (values: FormValues) => {
    const variables = {
      input: {
        reason: values.reason!,
        requestId: projectRequest.requestId!,
      },
    } as RejectProjectRequestMutationVariables;
    const res = await doMutation({ variables });

    return res?.data?.projectRequests?.reject as ValidationResponse;
  };

  return (
    <>
      <PageTitleRow title={`Reject Project Request ${projectRequest.name}`} />
      <MutationForm
        onSuccess={onSuccess}
        initialValues={initialFormValues}
        runMutation={runMutation}
      >
        <ErrorRenderer
          render={(error) => (
            <FormCard>
              <TextFormField
                formField="reason"
                label="Reason"
                helperText="Please provide the reason for rejecting this request"
                textarea
                required
              />

              <FormBottomRow
                buttonText="Reject"
                onCancel={() =>
                  navigate(
                    projectRequestRoute.toRoute(projectRequest.requestId!).path
                  )
                }
                errorMessage={error}
              />
            </FormCard>
          )}
        />
      </MutationForm>
      <Spinner open={loading} />
    </>
  );
};
