import React, { useMemo } from "react";
import FormBottomRow from "~/form-bottom-row";
import TextFormField from "~/text-form-field";
import { EmployeePicker } from "~/visuals/organisms/EmployeePickerFormField";
import EnumDropdownFormField from "~/visuals/organisms/EnumDropdownFormField";
import { useSnackBar } from "~/snackbar";
import {
  projectRequests as projectRequestsRoute,
  projectRequest as projectRequestRoute,
  customer as customerRoute,
} from "~/routes";
import { useNavigate, useParams } from "react-router-dom";
import PageTitleRow from "~/page-title-row";
import { Breadcrumb, useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import Spinner from "~/spinner";
import { Cell, Row } from "@material/react-layout-grid";
import ExpenseRateSheetPickerFormField from "~/visuals/organisms/ExpenseRateSheetPickerFormField";
import DatePickFormField from "~/visuals/organisms/DatePickFormField";
import { ValueRenderer } from "~/forms/ValueContext";
import Card from "@material/react-card";
import "./ApproveRequest.scss";
import { useApproveProjectRequestMutation } from "./mutation.hotchoc.generated";
import {
  BillingFrequencyType,
  CorpLocation,
  Customer,
  Division,
  ProjectRequest,
  ValidationResponse,
  Employee,
} from "~/gql/types";
import TaskRateSheetPickerFormField from "~/visuals/organisms/TaskRateSheetPickerFormField";
import moment, { Moment } from "moment";
import CorpLocationPickerFormField from "~/visuals/organisms/CorpLocationPickerFormField";
import DivisionPickerFormField from "~/visuals/organisms/DivisionPickerFormField";
import { Body2 } from "@material/react-typography";
import { getCustomerInfo } from "../utils";
import { GroupParams } from "../GroupReview/types";
import { CustomerPicker } from "~/visuals/organisms/CustomerPickerFormField";
import { requestGroupReview } from "../routes";
import { BadDebtWarning } from "~/bad-debt-warning";
import { formatFirstMiddleLast } from "~/utils/formatEmployeeName";
type ApproveProjectRequestProps = {
  projectRequest: ProjectRequest;
  initialValues?: any;
};

type FormValues = {
  projectSupervisor: Employee;
  billingAdmin: Employee;
  invoiceNotes: string;
  billingFrequency: BillingFrequencyType;
  customerNumber: string | null;
  expenseRateSheet: string | null;
  expenseRateSheetDate: Moment | null;
  taskRateSheet: string | null;
  taskRateSheetDate: Moment | null;
  division: Division;
  corpLocation: CorpLocation;
  customer: Customer | null;
};

const initialFormValues = {
  projectSupervisor: null,
  billingAdmin: null,
  invoiceNotes: null,
  billingFrequency: null,
  customerNumber: null,
  expenseRateSheet: null,
  expenseRateSheetDate: null,
  taskRateSheet: null,
  taskRateSheetDate: null,
  corpLocation: null,
  division: null,
  customer: null,
};

export const ApproveProjectRequest: React.FC<ApproveProjectRequestProps> = ({
  projectRequest,
  initialValues,
}) => {
  const addAlert = useSnackBar();
  const navigate = useNavigate();

  const hasCustomer = !!projectRequest.customer;
  const hasPendingCustomer = !!projectRequest.pendingCustomer;

  const params = useParams<GroupParams>();
  const groupId = params?.groupId;

  const backPath = groupId
    ? `${requestGroupReview}/${groupId}/1`
    : projectRequestRoute.toRoute(projectRequest.requestId!).path;

  const breadcrumbs = useMemo(
    () =>
      [
        hasCustomer
          ? {
              text: `Customer: ${projectRequest.customer!.name} (${
                projectRequest.customer!.number
              })`,
              to: customerRoute.toRoute(`${projectRequest.customer!.number}`)
                .path,
            }
          : null,
        { text: "Project Requests", to: projectRequestsRoute.path },
        {
          text: groupId
            ? `Request Group ${projectRequest.createdDate}`
            : `Pending Request ${projectRequest.name}`,
          to: backPath,
        },
        {
          text: `Approve${hasPendingCustomer ? " With Customer Request" : ""}`,
        },
      ].filter((x) => x),
    [projectRequest]
  ) as Breadcrumb[];

  useBreadcrumbs(breadcrumbs, [projectRequest]);

  const onSuccessMessage = hasCustomer
    ? "approved"
    : hasPendingCustomer
    ? "approved pending customer approval"
    : "approved pending new customer approval";

  const onSuccess = () => {
    addAlert({
      key: `${Math.random()}`,
      message: `${
        groupId ? `Group ${groupId}` : `Project request ${projectRequest.name}`
      } ${onSuccessMessage}`,
      isSuccess: true,
    });

    navigate(`${projectRequestsRoute.path}?state=Pending`);
  };

  const [doMutation, { loading }] = useApproveProjectRequestMutation();

  const runMutation = async (values: FormValues) => {
    const input = {
      memberId: projectRequest.requestId!,
      billingAdmin: values.billingAdmin.userPrincipalName!,
      projectSupervisor: values.projectSupervisor.userPrincipalName!,
      invoiceNotes: values.invoiceNotes,
      billingFrequency: values.billingFrequency as BillingFrequencyType,
      customerNumber: values.customerNumber || null,
      assignExpenseRateSheet:
        values.expenseRateSheet && values.expenseRateSheetDate
          ? {
              rateSheetName: values.expenseRateSheet,
              effectiveDate: values.expenseRateSheetDate.format("YYYY-MM-DD"),
            }
          : null,
      assignTaskRateSheet:
        values.taskRateSheet && values.taskRateSheetDate
          ? {
              rateSheetName: values.taskRateSheet,
              effectiveDate: values.taskRateSheetDate.format("YYYY-MM-DD"),
            }
          : null,
      corpLocation: values.corpLocation.id,
      division: values.division.id,
      customerSubAccountOf: projectRequest.requestedCustomer
        ? values.customer?.number ?? null
        : null,
    };

    const result = await doMutation({ variables: { input } });

    return result?.data?.projectRequests?.approveGroup as ValidationResponse;
  };

  const title = `Approve ${groupId ? "Request Group" : projectRequest.name}${
    hasPendingCustomer ? " With Customer Request" : ""
  }`;

  const projectManager = projectRequest.projectManager!;

  const { customer, buttonText } = getCustomerInfo(projectRequest);

  return (
    <>
      <PageTitleRow title={title} />
      <MutationForm
        onSuccess={onSuccess}
        initialValues={initialValues ?? initialFormValues}
        runMutation={runMutation}
      >
        <ErrorRenderer
          render={(error) => (
            <ValueRenderer
              render={(values) => (
                <div className="approve-project-request-page">
                  <Card className="approve-project-request">
                    <Row>
                      <Cell columns={6}>
                        <EmployeePicker
                          formField="billingAdmin"
                          label="Billing Admin"
                          roleName="Billing Admin"
                          activePayrollDate={moment().format("YYYY-MM-DD")}
                          helperText=""
                          required
                        />
                      </Cell>
                      <Cell columns={6}>
                        <EmployeePicker
                          formField="projectSupervisor"
                          label="Project Supervisor"
                          roleName="Project Supervisor"
                          activePayrollDate={moment().format("YYYY-MM-DD")}
                          helperText=""
                          required
                        />
                      </Cell>
                    </Row>
                    <Row>
                      <Cell columns={6}>
                        <CorpLocationPickerFormField
                          formField="corpLocation"
                          label="Location"
                          helperText=""
                          required
                        />
                      </Cell>
                      <Cell columns={6}>
                        <DivisionPickerFormField
                          formField="division"
                          label="Division"
                          helperText=""
                          required
                        />
                      </Cell>
                    </Row>
                    <Row>
                      <Cell columns={6}>
                        <DatePickFormField
                          formField="expenseRateSheetDate"
                          label="Expense Rate Sheet Effective Date"
                          helperText=""
                          required
                        />
                      </Cell>
                      <Cell columns={6}>
                        <ExpenseRateSheetPickerFormField
                          formField="expenseRateSheet"
                          helperText=""
                          label="Expense Rate Sheet"
                          effectiveDate={values.expenseRateSheetDate}
                          required
                        />
                      </Cell>
                    </Row>
                    <Row>
                      <Cell columns={6}>
                        <DatePickFormField
                          formField="taskRateSheetDate"
                          label="Task Rate Sheet Effective Date"
                          helperText=""
                          required
                        />
                      </Cell>
                      <Cell columns={6}>
                        <TaskRateSheetPickerFormField
                          formField="taskRateSheet"
                          helperText=""
                          label="Task Rate Sheet"
                          effectiveDate={values.taskRateSheetDate}
                          required
                        />
                      </Cell>
                    </Row>

                    <Row>
                      <Cell columns={6}>
                        <EnumDropdownFormField
                          formField="billingFrequency"
                          label="Billing Frequency"
                          helperText=""
                          enumType="BillingFrequencyType"
                          required
                        />
                      </Cell>
                      {!!projectRequest.requestedCustomer && (
                        <Cell columns={6}>
                          <CustomerPicker
                            formField="customer"
                            label="Create Customer As Sub Account Of"
                            helperText=""
                          />
                        </Cell>
                      )}
                    </Row>

                    <TextFormField
                      formField="invoiceNotes"
                      label="Invoice Notes"
                      helperText=""
                      textarea
                    />

                    <div className="approve-request-bottom-row">
                      <BadDebtWarning
                        {...{ customer: projectRequest.customer }}
                      />
                      <FormBottomRow
                        buttonText={buttonText}
                        onCancel={() => navigate(backPath)}
                        errorMessage={error}
                      />
                    </div>
                  </Card>
                  {!groupId && (
                    <Card className="project-request-info">
                      <Body2>{`Name: ${projectRequest.name}`}</Body2>
                      <Body2>{`Customer: ${customer.name}`}</Body2>
                      <Body2>{`State: ${
                        projectRequest.location!.state
                      }`}</Body2>
                      <Body2>{`County: ${
                        projectRequest.location!.county
                      }`}</Body2>
                      <Body2>
                        {`Service Description: ${
                          projectRequest.serviceDescription!.displayName
                        }`}
                      </Body2>
                      <Body2>
                        {`Project Manager: ${formatFirstMiddleLast(
                          projectManager
                        )}`}
                      </Body2>
                      <Body2>
                        {`Jobs Folder Required: ${
                          projectRequest.jobsFolderRequired ? "Yes" : "No"
                        }`}
                      </Body2>
                    </Card>
                  )}
                  {groupId && (
                    <Card className="project-request-info">
                      <Body2>{`Customer: ${customer.name}`}</Body2>
                      <Body2>{`Office: ${projectRequest.officeCode}`}</Body2>
                    </Card>
                  )}
                </div>
              )}
            />
          )}
        />
        <Spinner open={loading} />
      </MutationForm>
    </>
  );
};
