import * as Types from '../../../gql/types2';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"graphql2"}} as const;
export type EditGroupCustomerMutationVariables = Types.Exact<{
  input: Types.EditGroupCustomerCommand;
}>;


export type EditGroupCustomerMutation = { __typename?: 'MutationModel', projectRequests: { __typename?: 'ProjectRequestMutationModel', editGroupCustomer: { __typename?: 'ValidationResponse', errors: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string }> } } };


export const EditGroupCustomerDocument = gql`
    mutation editGroupCustomer($input: EditGroupCustomerCommand!) {
  projectRequests {
    editGroupCustomer(input: $input) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type EditGroupCustomerMutationFn = Apollo.MutationFunction<EditGroupCustomerMutation, EditGroupCustomerMutationVariables>;

/**
 * __useEditGroupCustomerMutation__
 *
 * To run a mutation, you first call `useEditGroupCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGroupCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGroupCustomerMutation, { data, loading, error }] = useEditGroupCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditGroupCustomerMutation(baseOptions?: Apollo.MutationHookOptions<EditGroupCustomerMutation, EditGroupCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditGroupCustomerMutation, EditGroupCustomerMutationVariables>(EditGroupCustomerDocument, options);
      }
export type EditGroupCustomerMutationHookResult = ReturnType<typeof useEditGroupCustomerMutation>;
export type EditGroupCustomerMutationResult = Apollo.MutationResult<EditGroupCustomerMutation>;
export type EditGroupCustomerMutationOptions = Apollo.BaseMutationOptions<EditGroupCustomerMutation, EditGroupCustomerMutationVariables>;