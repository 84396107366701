import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { useUngroupMutation } from "./ungroup.hotchoc.generated";
import { useSnackBar } from "~/snackbar";

import { projectRequest } from "~/routes";
import TextFormField from "~/text-form-field";
import FormBottomRow from "~/form-bottom-row";
import { useNavigate } from "react-router-dom";
import Spinner from "~/spinner";
import { useGroupReviewContext } from "./groupReviewContext";
import { ValidationResponse } from "~/gql/types";
import Button from "~/button";
type FormData = {
  notes: string | null;
};

export const Ungroup: React.FC = () => {
  const { projectRequests } = useGroupReviewContext();
  const [open, setOpen] = useState(false);
  const [runMutation, { loading }] = useUngroupMutation();
  const addAlert = useSnackBar();
  const navigate = useNavigate();

  const handleSubmit = async (values: FormData) => {
    const input = {
      notes: values.notes!,
      memberId: projectRequests[0].requestId,
    };
    const resolved = await runMutation({ variables: { input } });
    return resolved?.data?.projectRequests?.ungroup as ValidationResponse;
  };

  const onSuccess = () => {
    addAlert({
      key: `${Math.random()}`,
      isSuccess: true,
      message: "Requests ungrouped",
    });
    navigate(projectRequest.toRoute(projectRequests[0].requestId).path);
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>Ungroup</Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        data-open={open}
        className="ungroup-dialog"
      >
        <DialogTitle>Ungroup Requests</DialogTitle>
        <DialogContent>
          <MutationForm
            initialValues={{ notes: null }}
            runMutation={handleSubmit}
            onSuccess={onSuccess}
          >
            <TextFormField
              formField="notes"
              label="Reason"
              helperText="Please explain the reason for ungrouping"
              required
            />
            <ErrorRenderer
              render={(formError) => (
                <FormBottomRow
                  errorMessage={formError}
                  buttonText="Submit"
                  onCancel={() => setOpen(false)}
                />
              )}
            />
          </MutationForm>
        </DialogContent>
      </Dialog>
      <Spinner open={loading} />
    </>
  );
};
